<template>
	<div>
		<BaseLoading v-if="isLoading" is-full-page />
		<FormWarehouse
			v-else
			:logo="warehouseEdit.logo"
			:name-en="warehouseEdit.nameEN"
			:name-th="warehouseEdit.nameTH"
			:branch-id="warehouseEdit.com7BranchId"
			:is-active="warehouseEdit.isActive"
			:address-en="warehouseEdit.addressInfo.addressEN"
			:address-th="warehouseEdit.addressInfo.addressTH"
			:postcode="warehouseEdit.addressInfo.postcode"
			:province-id="warehouseEdit.addressInfo.provinceId"
			:district-id="warehouseEdit.addressInfo.districtId"
			:subdistrict-id="warehouseEdit.addressInfo.subdistrictId"
			:phone="warehouseEdit.addressInfo.phone"
			:delivery-phone="warehouseEdit.addressInfo.deliveryPhone"
			:email="warehouseEdit.addressInfo.email"
			:latitude="warehouseEdit.addressInfo.latitude"
			:longitude="warehouseEdit.addressInfo.longitude"
			:business-hours="warehouseEdit.businessHours"
			:remark="warehouseEdit.remark"
			:delivery-areas="warehouseEdit.deliveryAreas"
			:disabled-primary-button="isUpdating"
			:warehouse-types="warehouseTypes"
			:warehouse-type-id="warehouseEdit.warehouseType.id"
			is-edit-mode
			@onSubmit="handleSubmit"
		/>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import FormWarehouse from '@/components/FormWarehouse.vue';

export default {
	name: 'WarehouseEdit',
	components: {
		FormWarehouse,
	},
	data() {
		return {
			isLoading: false,
		};
	},
	computed: {
		...mapState('warehouses', {
			warehouseEdit: (state) => state.edit.data,
			isUpdating: (state) => state.edit.isUpdating,
		}),
		...mapGetters({
			warehouseTypes: 'warehouses/warehouseTypes',
		}),
	},
	async created() {
		const { id } = this.$route.params;
		this.isLoading = true;
		await Promise.all([
			this.fetchProvinces(),
			this.getWarehouse(id),
			this.getWarehouseTypes({ per_page: 'all' }),
		]);
		this.isLoading = false;
	},
	methods: {
		...mapActions({
			getWarehouse: 'warehouses/getWarehouse',
			getWarehouseTypes: 'warehouses/getWarehouseTypes',
			updateWarehouse: 'warehouses/updateWarehouse',
			fetchProvinces: 'addresses/fetchProvinces',
		}),
		async handleSubmit(params) {
			const id = this.$route.params.id;
			await this.updateWarehouse({ id, params });
		},
	},
};
</script>
